import Vue from "vue";
import Vuex from "vuex";
import { mutations } from "./mutation-types";
import { bookingDraftTemplate } from "./templates";
import Locales from "../lang/languages.js";
import axios from "axios";
Vue.use(Vuex);

const axios_profile = axios.create();
axios_profile.defaults.withCredentials = true;
axios_profile.defaults.baseURL = process.env.MIX_API_BASEURL_GUEST;

console.log("axios profile", axios_profile);

function generateReservationUrlPart(reservationQueryPayload, suffix = null) {
    console.log("generateReservationUrlPart input", reservationQueryPayload);
    let request_url = "bookings/reservation/" + reservationQueryPayload.uuid;
    if (reservationQueryPayload.secret !== null) {
        request_url =
            "reservations/" +
            reservationQueryPayload.uuid +
            "/" +
            reservationQueryPayload.secret;
    }
    if (suffix) {
        request_url = request_url + suffix;
    }
    console.log("request_url", request_url);
    return request_url;
}

const store = new Vuex.Store({
    state: () => ({
        profile: {},
        profileInterval: null,
        locales: Locales,
        locale: null,
        showHamburgerMenu: false,
        loading: false,
        search: {
            location: "",
            properties: [],
        },
        currentReservation: null,
        bookingDraft: {},
        isCheckingIn: false,
        isAwaitingReservationUpdate: false,
        hasBookingDraft: false,
        initialized: false,
        return_to_path: null,
        login_information_message: null,
        cookieConsent: {
            isDecided: false,
            isAllowed: false,
        },
    }),
    getters: {
        isLoggedIn: (state) => state.profile.hasOwnProperty("uuid"),
        isGuest: (state) => !state.profile.hasOwnProperty("uuid"),
        profile: (state) => state.profile,
        currentLocale: (state) =>
            state.locales.find((item) => item.key === state.locale),
        // isCookieDecided: (state) => state.cookieConsent.isDecided,
        // isCookieAllowed: (state) => state.cookieConsent.isAllowed,

        getCookieConsent(state) {
            console.log("getter state", state);
            let temp = state.cookieConsent;
            let cookie = sessionStorage.getItem("cookie_consent");
            if (cookie === null) {
                sessionStorage.setItem(
                    "cookie_consent",
                    JSON.stringify(state.cookieConsent)
                );
            }
            cookie = JSON.parse(cookie);
            return cookie;
        },
    },
    actions: {
        resetBookingDraft({ commit, dispatch }) {
            commit(mutations.BOOKING_DRAFT_RESET);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftBooker({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_BOOKER, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftMarketingSignup({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_MARKETING_SIGNUP, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftRooms({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_ROOMS, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftConfirmation({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_CONFIRMATION, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftPayment({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_PAYMENT, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftReservations({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_RESERVATIONS, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftOffer({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_OFFER, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftServices({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_SERVICES, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftPurpose({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_PURPOSE, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        async getUserReservations({ commit }) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                axios
                    .get("bookings/list")
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async getUserReservationCanCheckIn(
            { commit },
            reservationQueryPayload
        ) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    reservationQueryPayload,
                    "/checkin/can_checkin"
                );
                //request_url += '/checkin/can_checkin'
                console.log("can checkin request url", request_url);
                axios
                    .get(request_url)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async putUserReservationCancel({ commit }, reservationQueryPayload) {
            console.log("reservationQueryPayload", reservationQueryPayload);
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    reservationQueryPayload,
                    "/cancel"
                );
                console.log("request_url", request_url);
                axios
                    .put(request_url)
                    .then((response) => {
                        //if (response.data.data.status !== 'Confirmed') {
                        //commit(mutations.IS_CHECKING_IN_SET, false)
                        //}
                        commit(mutations.IS_AWAITING_RESERVATION_UPDATE, true);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async getUserReservation({ commit }, reservationQueryPayload) {
            console.log("reservationQueryPayload", reservationQueryPayload);
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    reservationQueryPayload
                );
                console.log("request_url", request_url);
                axios
                    .get(request_url)
                    .then((response) => {
                        commit(mutations.IS_AWAITING_RESERVATION_UPDATE, false);
                        if (response.data.data.status !== "Confirmed") {
                            commit(mutations.IS_CHECKING_IN_SET, false);
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async getUserReservationBooker({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    payload,
                    "/booker"
                );
                axios
                    .get(request_url, payload.data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async postUserReservationPreCheckIn({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    payload.id,
                    "/pre-checkin"
                );
                axios
                    .post(request_url, payload.data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async postUserReservationBooker({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    payload.id,
                    "/booker"
                );
                axios
                    .post(request_url, payload.data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async postUserReservationOpenLock({ commit }, payload) {
            commit(mutations.SET_LOADING, true);
            console.log("postUserReservationOpenLock");
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    payload.id,
                    "/unlock"
                );
                axios
                    .put(request_url)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async postUserReservationCheckInSignature({ commit }, payload) {
            console.log("postUserReservationCheckInSignature", payload);
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    payload.id,
                    "/checkin/signature"
                );
                axios
                    .post(request_url, payload.data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async postUserReservationCheckInCheckIn({ commit }, payload) {
            console.log("postUserReservationCheckInCheckIn", payload);
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    payload.id,
                    "/checkin/checkin"
                );
                axios
                    .put(request_url)
                    .then((response) => {
                        commit(mutations.IS_CHECKING_IN_SET, true);
                        commit(mutations.IS_AWAITING_RESERVATION_UPDATE, true);
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async postUserReservationCheckInIdentification({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    payload.id,
                    "/checkin/identification"
                );
                axios
                    .post(request_url, payload.data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async postUserReservationCheckInName({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    payload.id,
                    "/checkin/name"
                );
                axios
                    .post(request_url, payload.data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async postUserReservationCheckInPurpose({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                let request_url = generateReservationUrlPart(
                    payload.id,
                    "/checkin/purpose/" + payload.data.uuid
                );
                axios
                    .post(request_url, payload.data)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async validateBookingDraftReservations(
            { commit, dispatch },
            reservations
        ) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                axios
                    .post("bookings/validate_reservations", reservations)
                    .then((response) => {
                        commit(
                            mutations.BOOKING_DRAFT_SET_RESERVATIONS,
                            response.data.reservations
                        );
                        commit(
                            mutations.BOOKING_DRAFT_SET_UUID,
                            response.data.uuid
                        );
                        dispatch("setBookingDraftSessionStorage");
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async validateBookingDraftOfferBooker({ commit, dispatch }, booker) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                axios
                    .post("bookings/validate_booker", booker)
                    .then((response) => {
                        commit(
                            mutations.BOOKING_DRAFT_SET_BOOKER,
                            response.data
                        );
                        dispatch("setBookingDraftSessionStorage");
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async getBookingDraftTravelPurposes({ commit }) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                axios
                    .get("bookings/travel_purposes")
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async getBookingDraftOfferAvailableServices({
            commit,
            state,
            dispatch,
        }) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                axios
                    .post("bookings/get_services", {
                        arrival: state.bookingDraft.offer.arrival,
                        departure: state.bookingDraft.offer.departure,
                        rate_plan_id: state.bookingDraft.offer.ratePlan.id,
                        adults: state.bookingDraft.adults,
                    })
                    .then((response) => {
                        commit(
                            mutations.BOOKING_DRAFT_SET_AVAILABLE_SERVICES,
                            response.data.data
                        );
                        dispatch("setBookingDraftSessionStorage");
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async getBookingDraftOffers({ commit, state, dispatch }) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                axios
                    .post(
                        "bookings/get_offers/" +
                            state.bookingDraft.property.uuid,
                        state.bookingDraft.dates
                    )
                    .then((response) => {
                        commit(
                            mutations.BOOKING_DRAFT_SET_OFFERS,
                            response.data.data
                        );
                        dispatch("setBookingDraftSessionStorage");
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        setBookingDraftSessionStorage({ state }) {
            sessionStorage.setItem(
                "bookingDraft",
                JSON.stringify(state.bookingDraft)
            );
        },
        initStoreFromLocalStorage({ commit, dispatch }) {
            console.log("Init from LocalStorage");
            if (sessionStorage.getItem("bookingDraft") !== null) {
                try {
                    let stored_data = JSON.parse(
                        sessionStorage.getItem("bookingDraft")
                    );
                    console.log("booking draft from storage", stored_data);
                    commit(mutations.BOOKING_DRAFT_SET_ALL, stored_data);
                } catch (error) {
                    console.log("cant parse json data");
                }
                return;
            }
            dispatch("resetBookingDraft");
        },
        async setLocale({ dispatch, commit, state, getters }, locale) {
            let find = Locales.find((item) => item.key === locale);
            if (find === undefined) {
                console.log("invalid locale rejecting change", locale);
                locale = "en";
            }
            if (
                getters.isLoggedIn &&
                state.profile.preferred_locale !== locale
            ) {
                await dispatch("postLocale", locale);
            }
            if (!getters.isLoggedIn) {
                await axios
                    .post("locale", { locale: locale })
                    .then((response) => {
                        console.log(
                            "post laravel session locale",
                            locale,
                            response
                        );
                    })
                    .catch((error) => {
                        console.error("set session locale", error.response);
                    });
            }
            commit(mutations.SET_LOCALE, locale);
            commit(mutations.BOOKING_DRAFT_SET_BOOKER_LOCALE, locale);
            //set locale in booking draft
            sessionStorage.setItem("locale", locale);
        },
        async postLocale({ store, dispatch }, payload) {
            await axios
                .post("profile/locale", {
                    locale: payload,
                })
                .then((response) => {
                    console.log("post locale completed", payload, response);
                    dispatch("getProfile");
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {});
        },
        initLocale({ dispatch, commit, state, getters }) {
            //check profile data
            let locale = null;
            if (getters.isLoggedIn) {
                console.log(
                    "lang from profile",
                    state.profile.preferred_locale
                );
                locale = state.profile.preferred_locale;
            }
            if (getters.isLoggedIn === false) {
                //check session store
                if (sessionStorage.getItem("locale") !== null) {
                    console.log("lang from session");
                    locale = sessionStorage.getItem("locale");
                }
            }
            if (locale === null) {
                let navigator_language = null;
                navigator.languages.every((browser_language) => {
                    console.log(
                        "looping navigator.languages",
                        browser_language
                    );
                    navigator_language = state.locales.find(
                        (locale) => locale.key === browser_language
                    );
                    console.log(
                        "is there a hit for",
                        browser_language,
                        navigator_language
                    );
                    if (navigator_language !== undefined) {
                        return false;
                    }
                    return true;
                });
                console.log("lang from browser", navigator_language);
                locale = null;
                if (navigator_language !== undefined) {
                    locale = navigator_language.key;
                }
            }
            //Fallback
            if (locale === null) {
                console.log("have to fall back to german");
                locale = "de";
            }
            dispatch("setLocale", locale);
            // commit(mutations.SET_LOCALE, locale)
            // sessionStorage.setItem('locale', locale)
        },
        // initLocaleFromLocalStorage({dispatch, commit}) {
        //     console.log('Init locale from LocalStorage')
        //     if (sessionStorage.getItem('locale') !== null) {
        //         try {
        //             let stored_locale = sessionStorage.getItem('locale')
        //             console.log('locale from storage', stored_locale)
        //             // commit(mutations.SET_LOCALE, stored_locale)
        //             dispatch('setLocale', stored_locale)
        //         } catch (error) {
        //             console.log('cant parse json data')
        //         }
        //         return
        //     }
        // },
        setLocaleInLocalStorage(payload) {
            sessionStorage.setItem("locale", payload);
        },
        //setBookingDraftCheckIn({commit, dispatch}, payload) {
        //commit(mutations.BOOKING_DRAFT_SET_DATES, payload)
        //dispatch('setBookingDraftSessionStorage')
        //},
        setBookingDraftProperty({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_PROPERTY, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftDates({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_DATES, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        setBookingDraftServices({ commit, dispatch }, payload) {
            commit(mutations.BOOKING_DRAFT_SET_SERVICES, payload);
            dispatch("setBookingDraftSessionStorage");
        },
        resetSearchResult({ commit, dispatch }) {
            commit(mutations.RESET_SEARCH_RESULT);
            dispatch("setBookingDraftSessionStorage");
        },
        setReturnToPath({ commit }, payload) {
            commit(mutations.SET_RETURN_TO_PATH, payload);
        },
        setLoginInformationMessage({ commit }, payload) {
            commit(mutations.SET_LOGIN_INFORMATION_MESSAGE, payload);
        },
        setSearchResult({ commit }, payload) {
            commit(mutations.SET_SEARCH_RESULT, payload);
        },
        setLoading({ commit }, payload) {
            commit(mutations.SET_LOADING, payload);
        },
        setShowHamburgerMenu({ state, commit }, payload) {
            commit(mutations.SET_SHOW_HAMBURGER_MENU, payload);
        },
        async getProfileCurrentReservation({ commit, state }) {
            console.log("getProfileCurrentReservation Action");
            //commit(mutations.SET_LOADING, false)
            await axios
                .get("profile/current_reservation")
                .then((response) => {
                    if (response.status === 204) {
                        console.log("no current reservation", response.data);
                        commit(mutations.CURRENT_RESERVATION_SET, null);
                        return;
                    }
                    commit(
                        mutations.CURRENT_RESERVATION_SET,
                        response.data.data
                    );
                })
                .catch((error) => {
                    commit(mutations.CURRENT_RESERVATION_SET, null);
                    console.log(error.response);
                })
                .then(() => {
                    //commit(mutations.SET_LOADING, false)
                });
            console.log(state);
        },
        async getProfile({ commit, state, dispatch }, payload) {
            if (
                !payload ||
                (payload.hasOwnProperty("loading") && payload.loading === true)
            ) {
                commit(mutations.SET_LOADING, true);
            }
            await axios_profile
                .get("profile")
                .then((response) => {
                    //context.state.loggedIn = true
                    //context.state.profile = response.data.data
                    console.log("getProfile", response.data.data);
                    commit(mutations.SET_PROFILE, {
                        user: response.data.data,
                    });
                    // commit(
                    //     mutations.SET_PROFILE_INTERVAL,
                    //     setInterval(
                    //         function () {
                    //             dispatch("getProfile");
                    //             console.log("checking session");
                    //         }.bind(dispatch),
                    //         1000
                    //     )
                    // );
                    // dispatch("getProfileCurrentReservation");

                    //if bookerdraft booker is empty fill with profile data
                    if (state.bookingDraft.booker.firstName == "") {
                        let user = response.data.data.guest_detail;
                        if (user !== null) {
                            let booker = {
                                firstName: user.first_name,
                                middleInitial: user.middle_inital,
                                lastName: user.last_name,
                                companyName: user.company_name,
                                addressLine1: user.address_line1,
                                addressLine2: user.address_line2,
                                city: user.city,
                                postalCode: user.postal_code,
                                countryCode: user.country_code,
                                email: user.email,
                                phone: user.phone,
                            };
                            commit(mutations.BOOKING_DRAFT_SET_BOOKER, booker);
                        }
                    }
                    if (
                        state.bookingDraft.reservations[0].primaryGuest
                            .firstName == ""
                    ) {
                        let user = response.data.data.guest_detail;
                        if (user !== null) {
                            let guest = {
                                firstName: user.first_name,
                                lastName: user.last_name,
                                hasDifferentLocale: false,
                                locale: null,
                                email: user.email,
                                phone: user.phone,
                            };
                            commit(
                                mutations.BOOKING_DRAFT_SET_GUEST_FROM_USER,
                                guest
                            );
                        }
                    }
                    //console.log(response)
                })
                .catch((error) => {
                    console.log(error.response);
                    commit(mutations.RESET_PROFILE);
                })
                .then(() => {
                    commit(mutations.SET_LOADING, false);
                    commit(mutations.SET_INITIALIZED, true);
                    dispatch("initLocale");
                });
        },
        async logout({ commit }) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                axios
                    .post("logout")
                    .then(() => {
                        commit(mutations.RESET_PROFILE);
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async getCsrfCookie() {
            axios
                .get(process.env.MIX_ASSET_URL + "/sanctum/csrf-cookie")
                .then((response) => {
                    console.log(response);
                });
        },
        async postProfilePasswordChange({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                axios
                    .post("profile/password_change", payload)
                    .then((response) => {
                        // commit(mutations.SET_PROFILE, {
                        //     user: response.data.data,
                        // });
                        // dispatch("initLocale");
                        resolve();
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            reject(error.response.data);
                        }
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        async postProfile({ commit, dispatch }, payload) {
            return new Promise((resolve, reject) => {
                commit(mutations.SET_LOADING, true);
                axios
                    .post("profile", payload)
                    .then((response) => {
                        commit(mutations.SET_PROFILE, {
                            user: response.data.data,
                        });
                        dispatch("initLocale");
                        resolve();
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            reject(error.response.data);
                        }
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        // async postProfileGuestDetail({ commit }, payload) {
        //     return new Promise((resolve, reject) => {
        //         commit(mutations.SET_LOADING, true);
        //         axios
        //             .post("profile/guest_detail", payload)
        //             .then((response) => {
        //                 commit(mutations.SET_PROFILE, {
        //                     user: response.data.data,
        //                 });
        //                 resolve(response);
        //             })
        //             .catch((error) => {
        //                 reject(error);
        //             })
        //             .then(() => {
        //                 commit(mutations.SET_LOADING, false);
        //             });
        //     });
        // },
        login({ state, commit }, credentials) {
            commit(mutations.SET_LOADING, true);
            return new Promise((resolve, reject) => {
                if (state.profile.hasOwnProperty("uuid")) {
                    reject("Already logged in");
                }
                store.dispatch("getCsrfCookie");
                axios
                    .post("login", credentials)
                    .then((response) => {
                        console.log("Login Successfull", response.data.data);
                        store.dispatch("getProfile");
                        console.log("Resolving Promise");
                        resolve("success");
                        //console.log(response)
                    })
                    .catch((error) => {
                        console.log("Login error in store", error);
                        reject(error);
                    })
                    .then(() => {
                        commit(mutations.SET_LOADING, false);
                    });
            });
        },
        setCookieConsent({ commit }, payload) {
            sessionStorage.setItem("cookie_consent", JSON.stringify(payload));
            commit(mutations.SET_COOKIE_CONSENT, payload);
        },
    },
    mutations: {
        [mutations.SET_PROFILE](state, payload) {
            console.log("Mutating " + mutations.SET_PROFILE, payload.user);
            Vue.set(state, "profile", payload.user);
        },
        [mutations.SET_PROFILE_INTERVAL](state, payload) {
            console.log("Mutating " + mutations.SET_PROFILE_INTERVAL, payload);
            Vue.set(state, "profileInterval", payload.user);
        },
        [mutations.RESET_PROFILE](state) {
            console.log("Mutating " + mutations.RESET_PROFILE);
            Vue.set(state, "profile", {});
        },
        [mutations.SET_SHOW_HAMBURGER_MENU](state, payload) {
            console.log("Mutating " + mutations.SET_SHOW_HAMBURGER_MENU);
            state.showHamburgerMenu = payload;
        },
        [mutations.SET_LOADING](state, payload) {
            console.log("Mutating " + mutations.SET_LOADING, payload);
            Vue.set(state, "loading", payload);
        },
        [mutations.SET_SEARCH_RESULT](state, payload) {
            console.log("Mutating " + mutations.SET_SEARCH_RESULT, payload);
            Vue.set(state.search, "properties", payload.data);
            Vue.set(state.search, "location", payload.location);
        },
        [mutations.RESET_SEARCH_RESULT](state) {
            console.log("Mutating " + mutations.RESET_SEARCH_RESULT);
            Vue.set(state.search, "properties", []);
            Vue.set(state.search, "location", null);
        },
        [mutations.BOOKING_DRAFT_SET_PROPERTY](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_PROPERTY,
                payload
            );
            Vue.set(state.bookingDraft, "property", payload);
        },
        [mutations.BOOKING_DRAFT_SET_DATES](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_DATES,
                payload
            );
            Vue.set(state.bookingDraft, "dates", payload);
        },
        [mutations.BOOKING_DRAFT_SET_OFFERS](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_OFFERS,
                payload
            );
            Vue.set(state.bookingDraft, "offers", payload);
            // Vue.set(state.bookingDraft, 'offers', payload.sort(item => item.totalGrossAmount.amount).splice(1))
        },
        [mutations.BOOKING_DRAFT_SET_OFFER](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_OFFER,
                payload
            );
            Vue.set(state.bookingDraft, "offer", payload);
        },
        [mutations.BOOKING_DRAFT_SET_ROOMS](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_ROOMS,
                payload
            );
            Vue.set(state.bookingDraft, "rooms", payload);
            // Create reservations if necessary
            //if (state.bookingDraft.reservations.length > payload) {
            Vue.set(
                state.bookingDraft,
                "reservations",
                state.bookingDraft.reservations.splice(
                    payload - 1,
                    state.bookingDraft.reservations.length - payload - 1
                )
            );
            //}
            let start = 1;
            while (state.bookingDraft.reservations.length < parseInt(payload)) {
                console.log(
                    "Creating Reservation",
                    state.bookingDraft.reservations.length,
                    start
                );
                state.bookingDraft.reservations.push({
                    primaryGuest: {
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        hasDifferentLocale: false,
                        locale: null,
                    },
                    is_booker: false,
                    // is_booker: start === 1 ? true : false,
                    is_admin: true,
                });
                start++;
            }
        },
        [mutations.BOOKING_DRAFT_SET_AVAILABLE_SERVICES](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_AVAILABLE_SERVICES,
                payload
            );
            Vue.set(state.bookingDraft, "availableServices", payload);
        },
        [mutations.BOOKING_DRAFT_SET_BOOKER](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_BOOKER,
                payload
            );
            Vue.set(state.bookingDraft, "booker", payload);
        },
        [mutations.BOOKING_DRAFT_SET_GUEST_FROM_USER](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_GUEST_FROM_USER,
                payload
            );
            if (state.bookingDraft.reservations[0].primaryGuest) {
                Vue.set(
                    state.bookingDraft.reservations[0],
                    "primaryGuest",
                    payload
                );
            }
        },
        [mutations.BOOKING_DRAFT_SET_MARKETING_SIGNUP](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_MARKETING_SIGNUP,
                payload
            );
            Vue.set(state.bookingDraft, "marketing_signup", payload);
        },
        [mutations.BOOKING_DRAFT_SET_BOOKER_LOCALE](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_BOOKER_LOCALE,
                payload
            );
            console.log("booker", state.bookingDraft);
            Vue.set(state.bookingDraft.booker, "preferred_locale", payload);
        },
        [mutations.BOOKING_DRAFT_SET_SERVICES](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_SERVICES,
                payload
            );
            Vue.set(state.bookingDraft, "services", payload);
        },
        [mutations.BOOKING_DRAFT_SET_PURPOSE](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_PURPOSE,
                payload
            );
            Vue.set(state.bookingDraft, "purpose", payload.apaleo_purpose);
            Vue.set(state.bookingDraft, "travel_purpose_id", payload.id);
        },
        [mutations.BOOKING_DRAFT_SET_RESERVATIONS](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_RESERVATIONS,
                payload
            );
            Vue.set(state.bookingDraft, "reservations", payload);
            // Calculate amounts
        },
        [mutations.BOOKING_DRAFT_SET_UUID](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_UUID,
                payload
            );
            Vue.set(state.bookingDraft, "uuid", payload);
            // Calculate amounts
        },
        [mutations.BOOKING_DRAFT_SET_PAYMENT](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_PAYMENT,
                payload
            );
            Vue.set(state.bookingDraft, "payment", payload);
        },
        [mutations.IS_CHECKING_IN_SET](state, payload) {
            console.log("Mutating " + mutations.IS_CHECKING_IN_SET, payload);
            Vue.set(state, "isCheckingIn", payload);
        },
        [mutations.IS_AWAITING_RESERVATION_UPDATE](state, payload) {
            console.log(
                "Mutating " + mutations.IS_AWAITING_RESERVATION_UPDATE,
                payload
            );
            Vue.set(state, "isAwaitingReservationUpdate", payload);
        },
        [mutations.BOOKING_DRAFT_SET_CONFIRMATION](state, payload) {
            console.log(
                "Mutating " + mutations.BOOKING_DRAFT_SET_CONFIRMATION,
                payload
            );
            Vue.set(state.bookingDraft, "confirmation", payload);
        },
        [mutations.BOOKING_DRAFT_SET_ALL](state, payload) {
            console.log("Mutating " + mutations.BOOKING_DRAFT_SET_ALL, payload);
            Vue.set(state, "bookingDraft", payload);
        },
        [mutations.BOOKING_DRAFT_RESET](state) {
            console.log("Mutating " + mutations.BOOKING_DRAFT_RESET);
            Vue.set(state, "bookingDraft", bookingDraftTemplate);
        },
        [mutations.CURRENT_RESERVATION_SET](state, payload) {
            console.log(
                "Mutating " + mutations.CURRENT_RESERVATION_SET,
                payload
            );
            Vue.set(state, "currentReservation", payload);
        },
        [mutations.SET_INITIALIZED](state, payload) {
            console.log("Mutating " + mutations.SET_INITIALIZED, payload);
            Vue.set(state, "initialized", payload);
        },
        [mutations.SET_RETURN_TO_PATH](state, payload) {
            console.log("Mutating " + mutations.SET_RETURN_TO_PATH, payload);
            Vue.set(state, "return_to_path", payload);
        },
        [mutations.SET_LOGIN_INFORMATION_MESSAGE](state, payload) {
            console.log(
                "Mutating " + mutations.SET_LOGIN_INFORMATION_MESSAGE,
                payload
            );
            Vue.set(state, "return_to_path", payload);
        },
        [mutations.SET_LOCALE](state, payload) {
            // if (
            //     Locales.find((locale) => {
            //         locale.key === payload;
            //     }) === undefined
            // ) {
            //     console.log("invalid locale rejecting change", payload);
            //     payload = "en";
            // }
            console.log("Mutating " + mutations.SET_LOCALE, payload);
            Vue.set(state, "locale", payload);
            window.guest._i18n.locale = payload;
        },
        [mutations.SET_COOKIE_CONSENT](state, payload) {
            console.log("Mutating " + mutations.SET_COOKIE_CONSENT, payload);
            Vue.set(state, "cookieConsent", payload);
        },
    },
});

export default store;
