var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid gap-8",class:_vm.hasCheckinBalanceError
            ? 'mt-8 pt-8 border-t border-r-gray-light'
            : ''},[_c('h2',{staticClass:"text-2xl lg:text-3xl italic font-black text-r-green text-center lg:text-left"},[(_vm.reservation.status == 'CheckedOut')?[_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.checked_out.heading"))+"\n        ")]:_vm._e(),_vm._v(" "),(_vm.reservation.status == 'Canceled')?[_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.canceled.heading"))+"\n        ")]:_vm._e(),_vm._v(" "),(_vm.reservation.status == 'NoShow')?[_vm._v("\n            "+_vm._s(_vm.$t("reservations.summary.no_show.heading"))+"\n        ")]:_vm._e()],2),_vm._v(" "),_c('div',[_c('div',{staticClass:"text-center lg:text-left"},[(_vm.reservation.status == 'CheckedOut')?_c('span',[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.checked_out.lead"))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.reservation.status == 'Canceled')?_c('span',[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.canceled.lead", {
                        date: _vm.date(
                            _vm.reservation.cancellation_time,
                            "date_localized"
                        ),
                        time: _vm.date(
                            _vm.reservation.cancellation_time,
                            "time_localized"
                        ),
                    }))+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.reservation.status == 'NoShow')?_c('span',[_vm._v("\n                "+_vm._s(_vm.$t("reservations.summary.no_show.lead"))+"\n            ")]):_vm._e()])]),_vm._v(" "),(_vm.reservation.invoices.length > 0)?_c('div',{staticClass:"grid gap-5"},_vm._l((_vm.reservation.invoices),function(invoice){return _c('div',{key:invoice.uuid,staticClass:"grid lg:gap-2 gap-4 border border-r-gray-medium bg-r-gray-ultralight rounded-lg p-5"},[_c('div',{staticClass:"font-bold"},[_c('div',[_vm._v("\n                    "+_vm._s(_vm.$t("reservations.summary.invoice"))+":\n                    "+_vm._s(invoice.number)+"\n                    ("+_vm._s(_vm.$t(
                            "reservations.summary.invoice_type." +
                                invoice.type
                        ))+")\n                ")]),_vm._v(" "),_c('div',[_vm._v("\n                    "+_vm._s(_vm.$t("reservations.summary.invoice_date_delimiter"))+"\n                    "+_vm._s(_vm.date(invoice.invoice_date))+"\n                ")])]),_vm._v(" "),_c('div',{staticClass:"flex flex-row items-center justify-between"},[_c('div',[_vm._v("\n                    "+_vm._s(_vm.$t("reservations.summary.invoice_amount"))+":\n                    "+_vm._s(_vm.currency(
                            invoice.total_amount,
                            invoice.total_currency
                        ))+"\n                ")]),_vm._v(" "),_c('div',[(!invoice.payment_settled)?_c('div',[_vm._v("\n                        "+_vm._s(_vm.$t(
                                "reservations.summary.invoice_amount_unpaid"
                            ))+":\n                        "+_vm._s(_vm.currency(
                                invoice.outstanding_payment_amount,
                                invoice.outstanding_payment_currency
                            ))+"\n                    ")]):_c('div',[_c('span',{staticClass:"px-3 text-sm py-0.5 bg-r-gray text-white"},[_vm._v(_vm._s(_vm.$t(
                                    "reservations.summary.invoice_amount_paid"
                                )))])])])]),_vm._v(" "),_c('div',{staticClass:"flex justify-center"},[_c('div',[_c('app-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.openAssetInNewWindow(invoice)}}},[_vm._v(_vm._s(_vm.$t("reservations.summary.button_invoice_open")))])],1)])])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }